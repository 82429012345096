import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PAGE_LIMIT } from "constants/app.constant";
import BaseService from "services/BaseService";

// Async thunk action to fetch data from the API
export const fetchProfilFilters = createAsyncThunk(
  "profilfilters/fetch",
  async (query) => {
    const page = query?.page || 1;
    const limit = query?.limit || PAGE_LIMIT;
    const filter = query?.filter || "";
    try {
      const response = await BaseService.get(
        `/facetsProfile/?page=${page}&limit=${limit}&${filter}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch profil filters");
    }
  }
);

// Redux Toolkit slice
const profilFiltersSlice = createSlice({
  name: "profilFilters",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfilFilters.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProfilFilters.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchProfilFilters.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectData = (state) => state.profilFilters.data;
export const selectIsLoading = (state) => state.profilFilters.isLoading;
export const selectError = (state) => state.profilFilters.error;

export default profilFiltersSlice.reducer;
