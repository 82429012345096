export const ADD = "ADD";
export const DELETE = "DELETE";
export const EDIT = "EDIT";
export const VISUALIZE = "VISUALIZE";
export const MORE = "MORE";
export const ADD_CTA = { ADD: "Ajouter", PENDING: "Ajout en cours ..." };
export const NEXT_CTA = { NEXT: "Suivant", PENDING: "Suivant ..." };
export const SUMMARY_CTA = { NEXT: "Résumé", PENDING: "Résumé ..." };
export const EDIT_CTA = {
  EDIT: "Modifier",
  PENDING: "Modification en cours ...",
};
export const CONFLICT_MESSAGE = "Cette valeur est déjà utilisée";
export const SET_PASSWORD_CTA = {
  SET: "Valider",
  PENDING: "Validation en cours",
};

export const DISPLAY = "DISPLAY";
export const modes = {
  add: "add",
  edit: "edit",
};
