import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PAGE_LIMIT } from "constants/app.constant";
import BaseService from "services/BaseService";

// Async thunk action to fetch data from the API
export const fetchTaxes = createAsyncThunk("taxes/fetch", async (query) => {
  const page = query?.page || 1;
  const limit = query?.limit || PAGE_LIMIT;
  try {
    const response = await BaseService.get(`/tax/?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch taxes");
  }
});

// Redux Toolkit slice
const taxesSlice = createSlice({
  name: "taxes",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTaxes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchTaxes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectData = (state) => state.tax.data;
export const selectIsLoading = (state) => state.tax.isLoading;
export const selectError = (state) => state.tax.error;

export default taxesSlice.reducer;
