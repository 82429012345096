import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PAGE_LIMIT } from "constants/app.constant";
import BaseService from "services/BaseService";

// Async thunk action to fetch data from the API
export const fetchBrands = createAsyncThunk("brands/fetch", async (query) => {
  const page = query?.page || 1;
  const limit = query?.limit || PAGE_LIMIT;
  try {
    const response = await BaseService.get(
      `/brand/?page=${page}&limit=${limit}`
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch brands");
  }
});

// Redux Toolkit slice
const brandsSlice = createSlice({
  name: "brands",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    resetData: (state) => {
      state.data = [];
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchBrands.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const { resetData } = brandsSlice.actions;

export const selectData = (state) => state.brands.data;
export const selectIsLoading = (state) => state.brands.isLoading;
export const selectError = (state) => state.brands.error;

export default brandsSlice.reducer;
