import { combineReducers } from "redux";
import theme from "./theme/themeSlice";
import auth from "./auth";
import base from "./base";
import locale from "./locale/localeSlice";
import tax from "./tax/taxSlice";
import selectedAction from "./selectedAction/selectedActionSlice";
import currencies from "./currency/currencySlice";
import paymentMethods from "./paymentMethods/paymentMethodsSlice";
import channelCategory from "./channelCategory/channelCategorySlice";
import legalId from "./legalId/legalIdSlice";
import taxRegime from "./taxeRegime/taxRegimeSlice";
import legalForm from "./legalForm/legalFormSlice";
import countries from "./countries/countriesSlice";
import businessUnits from "./businessUnit/businessUnitsSlice";
import collaborators from "./collaborators/collaboratorsSlice";
import industries from "./industries/industriesSlice";
import partners from "./parnters/partnerssSlice";
import uploadHelper from "./uploadHelper/uploadHelperSlice";
import filters from "./filters/filtersSlice";
import profilFilters from "./profilFilters/profilFiltersSlice";
import attributes from "./attributes/attributesSlice";
import profilAttributes from "./profilAttributs/profilAttributsSlice";
import brands from "./brands/brandsSlice";
import units from "./units/unitsSlice";
import productCategories from "./productCategories/productCategoriesSlice";
import products from "./products/productsSlice";
import articles from "./articles/articlesSlice";
import catalogues from "./catalogues/cataloguesSlice";
import segments from "./segments/segmentsSlice";
import promotions from "./promotions/promotionsSlice";
import campaigns from "./campaign/campaignsSlice";

const rootReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    theme,
    auth,
    base,
    locale,
    tax,
    selectedAction,
    currencies,
    paymentMethods,
    channelCategory,
    legalId,
    taxRegime,
    legalForm,
    countries,
    businessUnits,
    collaborators,
    industries,
    partners,
    uploadHelper,
    filters,
    profilFilters,
    attributes,
    profilAttributes,
    brands,
    units,
    productCategories,
    products,
    articles,
    catalogues,
    segments,
    promotions,
    campaigns,
    ...asyncReducers,
  });
  return combinedReducer(state, action);
};

export default rootReducer;
