import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  action: "",
  data: {},
};

export const uploadHelperSlice = createSlice({
  name: "uploadHelper",
  initialState,
  reducers: {
    setUploadHelperAction: (state, { payload }) => {
      state.action = payload?.action;
      state.data = payload?.data;
    },
    resetUploadHelperAction: (state) => {
      state.action = "";
      state.data = {};
    },
  },
});

export const { setUploadHelperAction, resetUploadHelperAction } =
  uploadHelperSlice.actions;

export const selectUploadHelperAction = (state) => state.uploadHelper.action;
export const selectedUploadHelperData = (state) => state.uploadHelper.data;
export default uploadHelperSlice.reducer;
