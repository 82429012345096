import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PAGE_LIMIT } from "constants/app.constant";
import BaseService from "services/BaseService";

// Async thunk action to fetch data from the API
export const fetchProductCategories = createAsyncThunk(
  "productCategories/fetch",
  async (query) => {
    const page = query?.page || 1;
    const limit = query?.limit || PAGE_LIMIT;
    const filter = query?.filter || "";
    try {
      const response = await BaseService.get(
        `/productCategory/?page=${page}&limit=${limit}&${filter}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch product categories");
    }
  }
);

// Redux Toolkit slice
const productCategoriesSlice = createSlice({
  name: "productCategories",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductCategories.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProductCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchProductCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectData = (state) => state.productCategories.data;
export const selectIsLoading = (state) => state.productCategories.isLoading;
export const selectError = (state) => state.productCategories.error;

export default productCategoriesSlice.reducer;
