import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { PAGE_LIMIT } from "constants/app.constant";
import BaseService from "services/BaseService";

// Async thunk action to fetch data from the API
export const fetchProducts = createAsyncThunk(
  "products/fetch",
  async (query) => {
    const page = query?.page || 1;
    const limit = query?.limit || PAGE_LIMIT;
    const filter = query?.filter || "";
    try {
      const response = await BaseService.get(
        `/product/?page=${page}&limit=${limit}&${filter}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch product");
    }
  }
);

// Redux Toolkit slice
const productsSlice = createSlice({
  name: "products",
  initialState: {
    data: [],
    isLoading: false,
    error: null,
    fromSummary: false,
    modifyVariant: false,
  },
  reducers: {
    checkFromSummary: (state) => {
      state.fromSummary = true;
    },
    resetFromSummary: (state) => {
      state.fromSummary = false;
    },
    checkModifyVariant: (state) => {
      state.modifyVariant = true;
    },
    resetModifyVariant: (state) => {
      state.modifyVariant = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});
export const {
  checkFromSummary,
  resetFromSummary,
  checkModifyVariant,
  resetModifyVariant,
} = productsSlice.actions;

export const selectData = (state) => state.products.data;
export const selectIsLoading = (state) => state.products.isLoading;
export const selectError = (state) => state.products.error;
export const selectIsFromSummary = (state) => state.products.fromSummary;
export const selectIsModifyVariant = (state) => state.products.modifyVariant;

export default productsSlice.reducer;
